exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-health-quiz-age-js": () => import("./../../../src/pages/health-quiz/age.js" /* webpackChunkName: "component---src-pages-health-quiz-age-js" */),
  "component---src-pages-health-quiz-aortic-treatment-js": () => import("./../../../src/pages/health-quiz/aortic-treatment.js" /* webpackChunkName: "component---src-pages-health-quiz-aortic-treatment-js" */),
  "component---src-pages-health-quiz-completing-for-js": () => import("./../../../src/pages/health-quiz/completing-for.js" /* webpackChunkName: "component---src-pages-health-quiz-completing-for-js" */),
  "component---src-pages-health-quiz-echocardiogram-js": () => import("./../../../src/pages/health-quiz/echocardiogram.js" /* webpackChunkName: "component---src-pages-health-quiz-echocardiogram-js" */),
  "component---src-pages-health-quiz-heart-murmur-js": () => import("./../../../src/pages/health-quiz/heart-murmur.js" /* webpackChunkName: "component---src-pages-health-quiz-heart-murmur-js" */),
  "component---src-pages-health-quiz-index-js": () => import("./../../../src/pages/health-quiz/index.js" /* webpackChunkName: "component---src-pages-health-quiz-index-js" */),
  "component---src-pages-health-quiz-info-form-index-js": () => import("./../../../src/pages/health-quiz/info-form/index.js" /* webpackChunkName: "component---src-pages-health-quiz-info-form-index-js" */),
  "component---src-pages-health-quiz-info-table-js": () => import("./../../../src/pages/health-quiz/info-table.js" /* webpackChunkName: "component---src-pages-health-quiz-info-table-js" */),
  "component---src-pages-health-quiz-mitral-treatment-js": () => import("./../../../src/pages/health-quiz/mitral-treatment.js" /* webpackChunkName: "component---src-pages-health-quiz-mitral-treatment-js" */),
  "component---src-pages-health-quiz-more-info-index-js": () => import("./../../../src/pages/health-quiz/more-info/index.js" /* webpackChunkName: "component---src-pages-health-quiz-more-info-index-js" */),
  "component---src-pages-health-quiz-more-info-no-info-js": () => import("./../../../src/pages/health-quiz/more-info/no-info.js" /* webpackChunkName: "component---src-pages-health-quiz-more-info-no-info-js" */),
  "component---src-pages-health-quiz-more-info-no-symptoms-js": () => import("./../../../src/pages/health-quiz/more-info/no-symptoms.js" /* webpackChunkName: "component---src-pages-health-quiz-more-info-no-symptoms-js" */),
  "component---src-pages-health-quiz-problem-valve-js": () => import("./../../../src/pages/health-quiz/problem-valve.js" /* webpackChunkName: "component---src-pages-health-quiz-problem-valve-js" */),
  "component---src-pages-health-quiz-symptoms-js": () => import("./../../../src/pages/health-quiz/symptoms.js" /* webpackChunkName: "component---src-pages-health-quiz-symptoms-js" */),
  "component---src-pages-health-quiz-treatment-info-js": () => import("./../../../src/pages/health-quiz/treatment-info.js" /* webpackChunkName: "component---src-pages-health-quiz-treatment-info-js" */),
  "component---src-pages-health-quiz-treatment-symptoms-js": () => import("./../../../src/pages/health-quiz/treatment-symptoms.js" /* webpackChunkName: "component---src-pages-health-quiz-treatment-symptoms-js" */),
  "component---src-pages-health-quiz-valve-other-js": () => import("./../../../src/pages/health-quiz/valve-other.js" /* webpackChunkName: "component---src-pages-health-quiz-valve-other-js" */),
  "component---src-pages-health-survey-age-js": () => import("./../../../src/pages/health-survey/age.js" /* webpackChunkName: "component---src-pages-health-survey-age-js" */),
  "component---src-pages-health-survey-aortic-diagnosis-js": () => import("./../../../src/pages/health-survey/aortic-diagnosis.js" /* webpackChunkName: "component---src-pages-health-survey-aortic-diagnosis-js" */),
  "component---src-pages-health-survey-aortic-treatment-js": () => import("./../../../src/pages/health-survey/aortic-treatment.js" /* webpackChunkName: "component---src-pages-health-survey-aortic-treatment-js" */),
  "component---src-pages-health-survey-echocardiogram-js": () => import("./../../../src/pages/health-survey/echocardiogram.js" /* webpackChunkName: "component---src-pages-health-survey-echocardiogram-js" */),
  "component---src-pages-health-survey-finish-js": () => import("./../../../src/pages/health-survey/finish.js" /* webpackChunkName: "component---src-pages-health-survey-finish-js" */),
  "component---src-pages-health-survey-heart-murmur-js": () => import("./../../../src/pages/health-survey/heart-murmur.js" /* webpackChunkName: "component---src-pages-health-survey-heart-murmur-js" */),
  "component---src-pages-health-survey-index-js": () => import("./../../../src/pages/health-survey/index.js" /* webpackChunkName: "component---src-pages-health-survey-index-js" */),
  "component---src-pages-health-survey-infokit-for-js": () => import("./../../../src/pages/health-survey/infokit-for.js" /* webpackChunkName: "component---src-pages-health-survey-infokit-for-js" */),
  "component---src-pages-health-survey-other-info-js": () => import("./../../../src/pages/health-survey/other-info.js" /* webpackChunkName: "component---src-pages-health-survey-other-info-js" */),
  "component---src-pages-health-survey-treatment-info-js": () => import("./../../../src/pages/health-survey/treatment-info.js" /* webpackChunkName: "component---src-pages-health-survey-treatment-info-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-portal-js": () => import("./../../../src/templates/blogPortal.js" /* webpackChunkName: "component---src-templates-blog-portal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

